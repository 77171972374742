import Footer from "../components/Footer";
import Header from "../components/Header";
import {CssBaseline, Grid} from "@mui/material";
import Intro from "../components/Intro";
import AboutUs from "../components/AboutUs";
import Testimonials from "../components/Testimonials";
import ContactUs from "../components/ContactUs";
import background from "../assets/background.png";

export default function Home() {
    return (
        <Grid container sx={{
            minHeight: '100vh',
            display: 'flex',
            overflowY: 'auto',
            background: 'url(' + background + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
        }}>
            <CssBaseline/>
            <Header/>
            <Intro/>
            <AboutUs/>
            <Testimonials/>
            {/*<Spacer/>*/}
            <ContactUs/>
            <Footer/>
        </Grid>
    );
}