import {Button, Divider, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import Link from "@mui/material/Link";
import 'aos/dist/aos.css';

export default function ContactUs() {

    function openMail() {
        const email: string = "alamkin@lamkinsoftware.com"
        window.open(`mailto:${email}`);
    }

    return (
        <Grid container
              data-aos={"fade-up"}
              data-aos-duration={1000}
              id={'contact'}
              sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginY: 25,
                  marginX: 2,
              }}>
            <Typography variant="h3" color="text" align="center" fontWeight={"bold"} sx={{
                backgroundImage: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,245,255,1) 100%)',
                backgroundSize: 'cover',
                color: 'transparent',
                backgroundClip: 'text',
                textTransform: 'uppercase',
                m: 2
            }}>
                Let's Work Together! Schedule a Free First Consultation Today or Send Us an Email!
            </Typography>
            <Divider sx={{
                width: '100%',
                height: '2px',
                backgroundColor: 'rgba(0,245,255,1)',
            }}/>
            <Grid container sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                m: 2
            }}>
                <Link href={"https://calendly.com/lamkin-software-solutions/first-time-call"} target={"_blank"}
                      rel={"noopener"} m={1}>
                    <Button variant={'contained'} onClick={() => {
                        return false
                    }} sx={{
                        backgroundColor: 'rgba(30,30,30,.5)',
                        borderRadius: '12px',
                    }}>
                        <EditCalendarOutlinedIcon sx={{
                            fill: "white",
                            margin: 1,
                            height: '50px',
                            width: '50px',
                        }}/>
                    </Button>
                </Link>
                <Button variant={'contained'} onClick={() => {
                    openMail()
                }} sx={{
                    backgroundColor: 'rgba(30,30,30,.5)',
                    borderRadius: '12px',
                }}>
                    <EmailOutlinedIcon sx={{
                        fill: "white",
                        margin: 1,
                        height: '50px',
                        width: '50px',
                    }}/>
                </Button>
            </Grid>
        </Grid>
    )
}