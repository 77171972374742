import {Divider, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import 'aos/dist/aos.css';
import Lottie from "lottie-react";
import IntroAnimation from "../assets/intro.json";

export default function Intro() {
    return (
        <Grid container
              sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <Grid container
                  data-aos={"fade-right"}
                  data-aos-offset="400"
                  data-aos-duration={"1000"}
                  sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'left',
                alignItems: 'left',
                flex: .6,
            }}>
                <Typography variant="h1" color="text" align="left" fontWeight={"bold"} paddingLeft={'10px'}
                            minWidth={'400px'} sx={{
                    backgroundImage: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,245,255,1) 100%)',
                    backgroundSize: 'cover',
                    color: 'transparent',
                    backgroundClip: 'text',
                    textTransform: 'uppercase',
                }}>
                    Developing APIs and Full Stack Applications to meet your unique business needs.
                </Typography>
                <Divider sx={{
                    width: '100%',
                    height: '2px',
                    backgroundColor: 'rgba(0,245,255,1)',
                    marginTop: '1rem',
                    marginLeft: '15px',
                }}/>
                <Typography variant="h6" color="text" align="left" fontWeight={"bold"} paddingLeft={'10px'}
                            minWidth={'400px'} sx={{
                    textTransform: 'uppercase',
                }}>
                    FULLSTACK DEVELOPMENT, WEB HOSTING, API DEVELOPMENT, MOBILE DEVELOPMENT, DATABASE MANAGEMENT AND MORE!
                </Typography>
            </Grid>
            <Lottie
                data-aos={"fade-down"}
                data-aos-offset="400"
                data-aos-duration={"1000"}
                style={{
                    minWidth: '600px',
                    height: '600px',
                    border: 'none',
                    flex: .4,
                    marginLeft:'2rem',
                    filter: 'drop-shadow(0px 2px 2px rgba(0, 125, 255, 1))',
                }}
                animationData={IntroAnimation}
                loop={true}
            />
        </Grid>
    );
}