import {Button, Divider, Grid, Paper, Toolbar} from "@mui/material";
import Logo from "../assets/LSS_icon.png";
import Typography from "@mui/material/Typography";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {useEffect, useState} from "react";

export type section = {
    title: string;
    id: string;
}
export default function Header() {
    const sections: section[] = [
        {title: "About", id: "about"},
        {title: "Testimonials", id: "testimonials"},
        {title: "Get In Touch", id: "contact"},
    ];

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function onClick(id: string) {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    }

    function openMail() {
        const email: string = "alamkin@lamkinsoftware.com"
        window.open(`mailto:${email}`);
    }

    return (
        <Grid container data-testid={"header"} component={Paper} sx={{
            height: "fit-content",
            direction: "row",
            backgroundColor: 'rgba(30,30, 30, 0.05)',
        }}>
            {windowWidth < 650 ? null :
            <img data-testid={"lss-logo"} src={Logo} height={'100'} alt={"LSS Logo"} style={{
                float: "left",
                margin: "1rem",
            }}/>
            }
            {windowWidth < 900 ? null :
                <Typography variant={"h6"} color={"white"} sx={{
                    alignSelf: "center",
                }}>
                    Lamkin Software Solutions
                </Typography>
            }
            <Toolbar
                component="nav"
                variant="dense"
                sx={{justifyContent: 'end', overflowX: 'auto', minWidth: 'fit-content', flex: 1}}
            >
                {sections.map((section) => (
                    <Button
                        variant={"text"}
                        key={section.title}
                        sx={{p: 1, flexShrink: 0}}
                        onClick={() => {
                            onClick(section.id);
                        }}
                    >
                        <Typography variant={windowWidth < 500 ? "body2" : "h6"} color={"white"}>
                            {section.title}
                        </Typography>
                    </Button>
                ))}
                <Divider orientation={"vertical"} sx={{
                    backgroundColor: "white",
                    width: "1px",
                    m: 1,
                    height: "50%",
                }}/>
                <Button variant={'text'} onClick={() => {
                    openMail();
                }}>
                    <EmailOutlinedIcon sx={{
                        margin: 1,
                        color: "white",
                    }}/>
                </Button>
            </Toolbar>
        </Grid>
    );
}
