import {
    Card,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Theme,
    useMediaQuery,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import TechIcons from "../assets/tech_icons.png";
import {useEffect, useState} from "react";
import Lottie from "lottie-react";
import WhatWeDoAnimation from "../assets/what_we_do.json";
import WhoAreWeAnimation from "../assets/who_are_we.json";

export default function AboutUs() {
    const [isLgOrUp, setIsLgOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('lg')));

    useEffect(() => {
        const handleResize = () => {
            setIsLgOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Grid container id={'about'} direction={'column'} xs={12}>
            <Grid container direction={isLgOrUp ? 'row' : 'column'} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'fit-content',
            }}>
                <Card
                    data-aos={"fade-right"}
                    data-aos-offset="400"
                    data-aos-duration={"1000"}
                    sx={{
                        flex: .33,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: 'fit-content',
                        minHeight: '450px',
                        width: '100%',
                        padding: '2rem',
                        margin: '2rem',
                        borderRadius: '12px',
                        backgroundColor: 'rgba(30,30,30,.05)',
                    }}>
                    <Typography variant={'h3'} align={'left'} fontWeight={'bold'} sx={{
                        backgroundImage: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,245,255,1) 100%)',
                        backgroundSize: 'cover',
                        color: 'transparent',
                        backgroundClip: 'text',
                        textTransform: 'uppercase',
                    }}>
                        What Do We Do?
                    </Typography>
                    <Divider sx={{
                        width: '100%',
                        height: '2px',
                        backgroundColor: 'rgba(0,245,255,1)',
                        marginY: '1rem',
                    }}/>
                    <Typography variant={'h6'}>
                        At Lamkin Software Solutions, we specialize in developing customized software solutions that are
                        tailored to meet the distinctive requirements and challenges of our clients. Our team of
                        experienced developers and engineers work closely with our clients to gather in-depth insights
                        into their operations, goals and workflows. With this understanding, we design and develop
                        software applications that are highly scalable, efficient and user-friendly.
                    </Typography>
                </Card>
                <Lottie
                    data-aos={"fade"}
                    data-aos-duration={"1000"}
                    style={{
                        width: '100%',
                        height: '600px',
                        border: 'none',
                        flex: .34,
                        filter: 'drop-shadow(0px 2px 2px rgba(0, 125, 255, 1))'
                    }}
                    animationData={WhatWeDoAnimation}
                    loop={true}
                />
                <Card
                    data-aos={"fade-left"}
                    data-aos-offset="400"
                    data-aos-duration={"1000"}
                    sx={{
                        flex: .33,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        height: 'fit-content',
                        width: '100%',
                        padding: '2rem',
                        margin: '2rem',
                        borderRadius: '12px',
                        backgroundColor: 'rgba(30,30,30,.05)',
                    }}>
                    <Typography variant={'h3'} align={'left'} fontWeight={'bold'} sx={{
                        backgroundImage: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,245,255,1) 100%)',
                        backgroundSize: 'cover',
                        color: 'transparent',
                        backgroundClip: 'text',
                        textTransform: 'uppercase',
                    }}>
                        Provided Services
                    </Typography>
                    <Divider sx={{
                        width: '100%',
                        height: '2px',
                        backgroundColor: 'rgba(0,245,255,1)',
                        marginY: '1rem',
                    }}/>
                    <List style={{
                        listStyleType: 'disc'
                    }}>
                        <ListItem sx={{display: 'list-item'}}>
                            <Typography variant={'h6'}>Project Consulting and Support</Typography>
                        </ListItem>
                        <ListItem sx={{display: 'list-item'}}>
                            <Typography variant={'h6'}>Full Stack Application Development</Typography>
                        </ListItem>
                        <ListItem sx={{display: 'list-item'}}>
                            <Typography variant={'h6'}>Post Launch Maintenance</Typography>
                        </ListItem>
                        <ListItem sx={{display: 'list-item'}}>
                            <Typography variant={'h6'}>Web Hosting and Domain Registration</Typography>
                        </ListItem>
                        <ListItem sx={{display: 'list-item'}}>
                            <Typography variant={'h6'}>Database Management and Maintenance</Typography>
                        </ListItem>
                    </List>
                </Card>
            </Grid>
            <Grid container direction={isLgOrUp ? 'row' : 'column'} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Card
                    data-aos={"fade-right"}
                    data-aos-offset="400"
                    data-aos-duration={"1000"}
                    sx={{
                        flex: 0.5,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'fit-content',
                        width: '100%',
                        padding: '2rem',
                        margin: '2rem',
                        backgroundColor: 'rgba(30,30,30,.05)',
                        borderRadius: '12px',
                    }}>
                    <Typography variant={'h3'} align={'left'} fontWeight={'bold'} sx={{
                        backgroundImage: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,245,255,1) 100%)',
                        backgroundSize: 'cover',
                        color: 'transparent',
                        backgroundClip: 'text',
                        textTransform: 'uppercase',
                    }}>
                        Our Areas of Expertise
                    </Typography>
                    <Divider sx={{
                        width: '100%',
                        height: '2px',
                        backgroundColor: 'rgba(0,245,255,1)',
                        marginY: '1rem',
                    }}/>
                    <Typography variant={'h4'}>
                        When it comes to fullstack application development, we offer expertise in multiple tech
                        stacks in order to provide a solution that best fits your needs. We have experience with the
                        following technologies:
                        <List style={{
                            listStyleType: 'disc',
                            marginLeft: '2rem'
                        }}>
                            <ListItem sx={{display: 'list-item'}}>
                                <ListItemText primary={"Frontend Frameworks"} secondary={"React and Flutter"}/>
                            </ListItem>
                            <ListItem sx={{display: 'list-item'}}>
                                <ListItemText primary={"Backend Frameworks"} secondary={"Django and Spring Boot"}/>
                            </ListItem>
                            <ListItem sx={{display: 'list-item'}}>
                                <ListItemText primary={"Database Solutions"} secondary={"PostgreSQL and MongoDB"}/>
                            </ListItem>
                            <ListItem sx={{display: 'list-item'}}>
                                <ListItemText primary={"Hosting and Deployment"} secondary={"Amazon Web Services"}/>
                            </ListItem>
                        </List>
                    </Typography>
                </Card>
                <Grid item
                      data-aos={"fade-left"}
                      data-aos-offset="400"
                      data-aos-duration={"1000"}
                      sx={{
                          flex: 0.5,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                      }}>
                    <img src={TechIcons} alt={"Tech Icons"} style={{
                        width: '100%',
                        filter: 'drop-shadow(0px 2px 2px rgba(0, 125, 255, 1))',
                    }}/>
                </Grid>
            </Grid>
            <Grid container direction={isLgOrUp ? 'row' : 'column'} justifyContent={'center'} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                {isLgOrUp ?
                    <Lottie
                        data-aos={"fade-right"}
                        data-aos-offset="400"
                        data-aos-duration={"1000"}
                        style={{
                            width: '100%',
                            height: '600px',
                            border: 'none',
                            flex: .33,
                        }}
                        animationData={WhoAreWeAnimation}
                        loop={true}
                    />
                    : null}
                <Card
                    data-aos={"fade-left"}
                    data-aos-offset="400"
                    data-aos-duration={"1000"}
                    sx={{
                        flex: .67,
                        display: 'flex',
                        flexDirection: isLgOrUp ? 'row' : 'column',
                        justifyContent: 'start ',
                        height: 'fit-content',
                        minHeight: isLgOrUp ? '325px' : '550px',
                        width: '90%',
                        padding: '2rem',
                        borderRadius: '12px',
                        backgroundColor: 'rgba(30,30,30,.05)',
                        m: 3,
                    }}>
                    <Grid container sx={{
                        flex: .5
                    }}>

                        <Typography variant={'h3'} align={'left'} fontWeight={'bold'} sx={{
                            backgroundImage: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,245,255,1) 100%)',
                            backgroundSize: 'cover',
                            color: 'transparent',
                            backgroundClip: 'text',
                            textTransform: 'uppercase',
                        }}>
                            Who Are We?
                        </Typography>
                        <Divider sx={{
                            width: '100%',
                            height: '2px',
                            backgroundColor: 'rgba(0,245,255,1)',
                            marginY: '1rem',
                        }}/>
                        <Typography variant={'h5'} align={'left'}>
                            We are a small group of talented developers who are passionate about creating software that
                            helps businesses get their unique ideas to fruition. Our developers have years of experience
                            in a multitude of industries including fintech, military and sports entertainment.
                        </Typography>
                    </Grid>
                    <Grid container sx={{
                        flex: .5,
                        marginX: isLgOrUp ? '2rem' : '0rem',
                        alignItems: isLgOrUp ? 'flex-end' : 'center',
                    }}>
                        <Typography variant={'h5'} align={'left'}>
                            Our team understands that a project requiring software development can feel daunting. That
                            is why we aim to provide a high level of customer service through clear communication with
                            our clients surrounding timelines, budgets and more, so the software development process can
                            be as stress-free as possible.
                        </Typography>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
}