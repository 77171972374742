import {Card, Divider, Grid, Theme, useMediaQuery} from "@mui/material";
import Typography from "@mui/material/Typography";
import 'aos/dist/aos.css';
import {useEffect, useState} from "react";

export default function Testimonials() {
    const [isLgOrUp, setIsLgOrUp] = useState(useMediaQuery((theme: Theme) => theme.breakpoints.up('lg')));

    useEffect(() => {
        const handleResize = () => {
            setIsLgOrUp(window.innerWidth >= 960);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <Grid container id={'testimonials'} direction={isLgOrUp ? 'row' : 'column'} sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'fit-content',
        }}>
            <Card
                data-aos={"fade-right"}
                data-aos-offset="400"
                data-aos-duration={"1000"}
                sx={{
                    flex: .5,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'fit-content',
                    width: '100%',
                    padding: '2rem',
                    margin: '2rem',
                    backgroundColor: 'rgba(30,30,30,.05)',
                    borderRadius: '12px',
                }}>
                <Typography variant={'h3'} align={'left'} fontWeight={'bold'} sx={{
                    backgroundImage: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,245,255,1) 100%)',
                    backgroundSize: 'cover',
                    color: 'transparent',
                    backgroundClip: 'text',
                    textTransform: 'uppercase',
                }}>
                    Client Testimonials
                </Typography>
                <Divider sx={{
                    width: '100%',
                    height: '2px',
                    backgroundColor: 'rgba(0,245,255,1)',
                    marginY: '1rem',
                }}/>
            </Card>
            <Card
                data-aos={"fade-left"}
                data-aos-offset="400"
                data-aos-duration={"1000"}
                sx={{
                    flex: .5,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'fit-content',
                    width: '100%',
                    padding: '2rem',
                    margin: '2rem',
                    backgroundColor: 'rgba(30,30,30,.05)',
                    borderRadius: '12px',
                }}>
                <Typography variant={'h6'} align={'left'} fontStyle={'italic'}>
                    “I worked with LSS to bring a brand-new idea to life with a dynamic website and the backend functionality to make it easy to manage. The LSS team took my idea and worked with me on a reasonable timeline and also stayed on budget throughout the whole development and launch process. Their communication was clear and on point throughout the entire project. There is no way DragRaceBracketBonanza.com would have come to life without the team from LSS.”
                </Typography>
                <Typography variant={'subtitle2'} align={'left'}>
                    - Elon Werner, Founder of Werner Communications and Drag Race Bracket Bonanza
                </Typography>
            </Card>
        </Grid>
    );
}