import React from 'react';
import './App.css';
import {createTheme, responsiveFontSizes, Theme, ThemeProvider} from "@mui/material";
import {Routes, Route, BrowserRouter} from "react-router-dom";
import Home from "./pages/Home";
import AOS from 'aos';

function App() {

    const fontSizes = {
        xs: 12,
        sm: 14,
        md: 16,
        lg: 18,
        xl: 20,
    }

    let theme: Theme = createTheme({
        palette: {
            mode: 'dark',
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920,
            },
        },
        typography: {
            fontSize: fontSizes.sm,
        },
    });

    theme = responsiveFontSizes(theme, {
        breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
        disableAlign: true,
        factor: 3,
        variants: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2'],
    });

    AOS.init();

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
