import {Grid, Paper} from "@mui/material";
import Logo from "../assets/LSS_Logo.png";
import Copyright from "./Copyright";

export default function Footer() {
    return (
        <Grid data-testid={"footer"} container
              sx={{
                  left: 0,
                  bottom: 0,
                  width: '100%',
                  height: 'fit-content',
                  backgroundColor: 'rgba(30,30,30,.05)',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
              }}
              component={Paper}
              elevation={2} p={5}>
            <img data-testid={"lss-logo"} src={Logo} height={'100'} width={'100'} alt={"LSS Logo"}/>
            <Copyright sx={{textAlign: "left"}}/>
        </Grid>
    );
};